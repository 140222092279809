<template>
  <div class="h-full flex flex-col gap-2 pb-4 text-white divide-y divide-white/10 min-h-[80vh] md:min-h-[40rem]">
    <!-- step 1 -->
    <TransitionCollapse>
      <section class="pb-4" v-if="steper >= 1">
        <h3 class="topic my-4">
          選擇牌組
          <span class="subtitle">選擇你的牌組來源</span>
        </h3>

        <div class="grid grid-cols-2 md:grid-cols-4 gap-2">
          <div class="relative h-full w-full">
            <button class="item default-transition active" @click.stop="showMenu = true" :disabled="running">
              <BookmarkIcon class="icon size-6" />
              <span class="text-sm font-mono">我的牌組</span>
            </button>

            <TransitionCollapse>
              <UIDropDown
                ref="dropDown"
                :menus="menus"
                v-if="showMenu"
                :isSearch="true"
                :parentSize="48"
                @select="
                  e => {
                    selectDeck(e.deck)
                  }
                "
              />
            </TransitionCollapse>
          </div>

          <div v-if="status" class="col-span-full md:col-span-3 w-full flex items-center gap-2">
            <div
              class="rounded-full p-1"
              :class="{
                'text-white': status === 'loading',
                'bg-green-300/20 text-green-500': status === 'success',
                'bg-red-300/20 text-red-500': status === 'error'
              }"
            >
              <ArrowPathIcon v-if="status === 'loading'" class="flex-none size-5 animate-spin" />
              <CheckIcon v-else-if="status === 'success'" class="flex-none size-5" />
              <XMarkIcon v-else-if="status === 'error'" class="flex-none size-5" />
            </div>

            <span
              class="font-mono"
              :class="{
                'text-white': status === 'loading',
                'text-green-500': status === 'success',
                'text-red-500': status === 'error'
              }"
            >
              {{ status === 'loading' ? 'Loading' : status === 'error' ? '無效代碼' : status === 'success' ? '找到：' + nowDeck?.title : '' }}
            </span>
          </div>
        </div>
      </section>
    </TransitionCollapse>

    <!-- step 2 -->
    <TransitionCollapse>
      <section class="pb-4" v-if="steper >= 2">
        <h3 class="topic my-4">
          上傳設定
          <span class="subtitle">更新上傳 DeckLog 內容</span>
        </h3>

        <h4 class="font-bold mr-auto text-zinc-300 mt-4 mb-2">選擇上傳位置</h4>

        <div class="grid grid-cols-2 md:grid-cols-4 gap-2">
          <button
            class="item default-transition"
            :class="{
              active: type === 'decklog'
            }"
            @click="type = 'decklog'"
            :disabled="running"
          >
            <img src="/images/decklog.png" class="icon size-6" />
            <span class="text-sm font-mono">DeckLog JP</span>
          </button>
          <button
            class="item default-transition"
            :class="{
              active: type === 'decklogEn'
            }"
            @click="type = 'decklogEn'"
            :disabled="running"
          >
            <img src="/images/decklog.png" class="icon size-6" />
            <span class="text-sm font-mono">DeckLog EN</span>
          </button>
        </div>

        <div class="mt-4 grid grid-cols-2 md:grid-cols-4 gap-2">
          <button
            v-for="r in rules"
            :key="r.field"
            class="item default-transition"
            :class="{
              active: rule === r.field
            }"
            @click="rule = r.field"
            :disabled="running"
          >
            <TrophyIcon class="icon size-6" />
            <span class="text-sm font-mono">{{ r.name }}</span>
          </button>
        </div>

        <div class="mt-4 flex items-center gap-2 p-2 rounded-xl bg-amber-500/20 text-white" v-if="nowDeck && existDecklogCode(type, rule)">
          <ExclamationCircleIcon class="flex-none size-6 stroke-2 text-amber-300" />
          <span
            >此牌組已經有上傳牌組，請確認是否覆蓋。代碼：
            <a
              class="underline underline-offset-4 decoration-2"
              :href="decklogWebsite(type === 'decklogEn', existDecklogCode(type, rule))"
              target="_blank"
            >
              {{ existDecklogCode(type, rule) }}
            </a>
          </span>
        </div>

        <div class="mt-4 p-2 rounded-xl bg-zinc-700/20 text-white">
          <h4 class="topic !text-base !text-zinc-200 mb-2">
            [可選] 取得上傳者登入授權
            <span class="subtitle">請確認已經登入對應 DeckLog 網站，並且使用 Chrome 安裝 Extension "DeckLog Cookie Retriever"。</span>
          </h4>
          <div class="grid grid-cols-2 md:grid-cols-4 gap-2">
            <div class="w-full h-full relative">
              <button class="item default-transition" @click="getDeckLogLoginAuth" :disabled="running">
                <span class="text-sm font-mono">取得資料</span>
              </button>
            </div>

            <div v-if="authStatus" class="col-span-full md:col-span-3 w-full flex items-center gap-2">
              <span
                class="font-mono"
                :class="{
                  'text-white': authStatus === 'loading',
                  'text-green-500': authStatus === 'success',
                  'text-red-500': authStatus === 'error'
                }"
              >
                {{
                  authStatus === 'loading' ? 'Loading' : authStatus === 'error' ? '無效代碼' : authStatus === 'success' ? '找到：' + authCookie : ''
                }}
              </span>
            </div>
          </div>
        </div>

        <h4 class="topic !text-base !text-zinc-200 mt-4 mb-2">
          牌組標題
          <span class="subtitle">最多25字以內，並結尾包含 " By 貓罐子" 7字。</span>
        </h4>
        <div class="item default-transition">
          <UIInput type="text" placeholder="標題" v-model="title" :disabled="running" />
          <div class="flex-none text-sm font-zinc-300">By 貓罐子</div>
        </div>

        <h4 class="font-bold mr-auto text-zinc-300 mt-4 mb-2">牌組描述</h4>
        <div class="item default-transition">
          <UIInput type="textarea" rows="3" placeholder="描述" v-model="description" :disabled="running" />
        </div>

        <div class="mt-8 flex justify-center">
          <button class="item md:!w-[20rem] justify-center default-transition" :disabled="running || !title || !type" @click="doSubbmit">
            <CheckIcon class="icon size-6" />
            <span> 設定完成 </span>
          </button>
        </div>
      </section>
    </TransitionCollapse>

    <TransitionCollapse>
      <section class="pb-4" id="process" v-if="steper >= 3">
        <h3 class="topic my-4">
          上傳牌組
          <span class="subtitle">貓罐子努力丟上去中</span>
        </h3>

        <div class="flex flex-col items-center gap-4">
          <button v-for="p in process" :key="p.field" class="item justify-start default-transition">
            <CheckIcon v-if="p.status === 'success'" class="icon text-green-500 size-6" />
            <ArrowPathIcon v-else-if="p.status === 'loading'" class="icon text-cyan-500 size-6 animate-spin" />
            <MinusIcon v-else-if="p.status === 'pending'" class="icon size-6" />
            <XMarkIcon v-else class="icon text-red-500 size-6" />
            <div class="flex flex-col items-start gap-1">
              <span> {{ p.name }} </span>

              <a :href="p.message" target="_blank" v-if="p.field === 'finish' && p.status === 'success'" class="text-green-400 hover-green-500">
                {{ p.message }}
              </a>

              <span
                v-else-if="p.message"
                class="text-sm text-left"
                :class="{
                  'text-green-400': p.status === 'success',
                  'text-red-400': p.status === 'error'
                }"
              >
                {{ p.message }}
              </span>
            </div>

            <a
              :href="p.message"
              target="_blank"
              v-if="p.field === 'finish' && p.status === 'success'"
              class="ml-auto rounded-full p-2 bg-zinc-700/50 hover:bg-zinc-700 text-white"
            >
              <ArrowTopRightOnSquareIcon class="flex-none size-5" />
            </a>
          </button>
        </div>

        <div v-if="process[process.length - 1].status === 'success'" class="mt-8 flex justify-center">
          <button @click="doRouter" class="item md:!w-[20rem] justify-center default-transition">
            <ArrowUturnLeftIcon class="icon size-6" />
            <span> 返回牌組 </span>
          </button>
        </div>
      </section>
    </TransitionCollapse>
  </div>
</template>

<script setup>
import {
  ArrowUturnLeftIcon,
  ExclamationCircleIcon,
  BookmarkIcon,
  ArrowPathIcon,
  CheckIcon,
  XMarkIcon,
  MinusIcon,
  TrophyIcon,
  ArrowTopRightOnSquareIcon
} from '@heroicons/vue/24/outline'
import { decklogWebsite } from '@/constants/api'
const { query } = defineProps({
  query: {
    type: Object,
    default: {}
  }
})

const { showPane } = storeToRefs(usePreferenceStore())
const { decks } = storeToRefs(useDeckStore())

const { process, doProcess, prepareData } = useDeckLog()
const { t } = useNuxtApp().$i18n
const router = useRouter()

const status = ref(false)
const steper = ref(1)

// step 1
const dropDown = ref()
onClickOutside(dropDown, () => {
  showMenu.value = false
})

const showMenu = ref(false)
const menus = computed(() => [
  { name: t('deckDefalut'), deck: null, icon: BookmarkIcon },
  ...decks.value.map(e => ({
    name: e.title || '-',
    deck: e,
    icon: BookmarkIcon
  }))
])
const nowDeck = ref()
const selectDeck = e => {
  showMenu.value = false

  nowDeck.value = e
  title.value = e ? e.title : ''
  description.value = e ? e.description : ''

  status.value = e ? 'success' : null
  steper.value = e ? 2 : 1
}

// step 2
const type = ref('decklog') // or decklogEn
const rules = [
  { name: 'Neo Standard', field: 'neoStandard' },
  { name: 'Title', field: 'title' },
  { name: 'Standard', field: 'standard' },
  { name: 'Other', field: 'other' }
]
const rule = ref('neoStandard')
const title = ref('')
const description = ref('')
const authCookie = ref('')
const authStatus = ref('')

const getDeckLogLoginAuth = async () => {
  authStatus.value = 'loading'

  function requestCookies () {
    return new Promise((resolve, reject) => {
      const listener = event => {
        if (event.data && event.data.type === 'cookieData') {
          window.removeEventListener('message', listener) // 確保只監聽一次
          resolve(event.data.data)
        }
      }

      window.addEventListener('message', listener)
      window.postMessage({ action: 'getCookies' }, '*')

      // 設定超時機制（5 秒後超時）
      setTimeout(() => {
        window.removeEventListener('message', listener)
        reject('擴充功能沒有回應')
      }, 5000)
    })
  }

  try {
    const cookies = await requestCookies()

    // 根據 `isEn` 選擇對應的 CAKEPHP Cookie
    const targetDomain = type.value === 'decklogEn' ? 'decklog-en.bushiroad.com' : 'decklog.bushiroad.com'
    const authToken = cookies[targetDomain] || ''

    if (authToken) {
      console.log(`取得 ${targetDomain} 的 CAKEPHP Cookie:`, authToken)
      authCookie.value = authToken
      authStatus.value = 'success'
    } else {
      throw new Error(`未取得 ${targetDomain} 的 CAKEPHP Cookie`)
    }
  } catch (error) {
    console.error('取得 Cookie 失敗:', error)
    authCookie.value = ''
    authStatus.value = 'error'
  }
}

const existDecklogCode = (type, rule) => {
  const ruleCodes = {
    neoStandard: 'N',
    title: 'T',
    standard: 'S',
    other: 'O'
  }
  return nowDeck.value[type].find(e => e.rule === ruleCodes[rule])?.code
}

const doSubbmit = async () => {
  steper.value = 3
  running.value = true

  // ui
  setTimeout(() => {
    document.getElementById('process').scrollIntoView({ behavior: 'smooth' })
  }, 300)

  const data = prepareData({
    code: nowDeck.value.code,
    type: type.value,
    title: title.value,
    description: description.value,
    cards: nowDeck.value.cards,
    rule: rule.value,
    cookie: authCookie.value
  })
  await doProcess(data)

  running.value = false
}

// step 3
const running = ref(false)

const doRouter = () => {
  showPane.value = null
  router.push(`/deck/${nowDeck.value.code}`)
}

onMounted(() => {
  setTimeout(() => {
    if (query.open === 'deckLogUpload' && query.code) {
      const deck = decks.value.find(e => e.code === query.code)
      if (deck) {
        selectDeck(deck)
      }
    }
  }, 500)
})
</script>

<style lang="sass" scoped>

.topic
  @apply text-lg font-bold mr-auto text-zinc-200
  .subtitle
    @apply block font-normal text-zinc-400 text-xs

.item
  @apply w-full flex items-center gap-2 p-2 rounded-xl ring-2 ring-zinc-800
  &:not(.active)
    @apply bg-zinc-900/50 text-zinc-400 hover:bg-zinc-800 focus-within:bg-zinc-800 active:bg-zinc-800 disabled:text-zinc-800
  &.active
    @apply bg-gradient-to-r from-blue-500 to-cyan-500 shadow-sky-500/50 text-white
  .icon
    @apply flex-none stroke-2
</style>
